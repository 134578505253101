<script setup>
import { RouterView } from 'vue-router';
import { onMounted, ref } from 'vue';
import { instalarHeaderFooter } from 'https://arpen-header-footer-prod.codebit.biz/header-footer.min.js';
import { useAuthStore } from '@/stores/auth';
import helpers from '@/helpers';

const authStore = useAuthStore();
const groupsUser = ref(null);
const checkGroup = ref(false);

onMounted(async () => {

  function getCookie(name) {
    let cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
          return cookieValue;
      }
    }
    return null;
  }

  groupsUser.value = getCookie("groups")
  var decodeToken = JSON.parse(atob(groupsUser.value))
  groupsUser.value = JSON.parse(decodeToken.message)
  checkGroup.value = groupsUser.value.includes("/Registrador Civil")
  groupsUser.value = groupsUser.value.join().replace(/[/]/g, "")

  instalarHeaderFooter({
    usuario: {
      nome: authStore?.user?.name,
      cpf: authStore?.user?.cpf ? helpers.formatarCPF(authStore.user.cpf) : null,
      foto: "",
      grupos: groupsUser.value,
    },
    urlLogin: authStore?.urlLogin(),
    urlLogout: authStore?.urlLogout(),
    token: authStore?.getToken(),
  });

});
</script>

<template>
  <div id="appContainer">
    <div id="header"></div>
    <span v-if="checkGroup == false">
      <div class="container">
        <br /><br />
        <SerpTipography type="t3" tag="h3" class="col-span-8" style="font-size:40px; color:#666666;">
          Atenção
        </SerpTipography>
        <br /><br />
        <small>Usuário sem permissão para o acesso ao sistema</small>
        <br /><br /><br /><br />
      </div>
    </span>

    <span v-else>
      <RouterView />
    </span>
    
    <div id="footer"></div>
  </div>
</template>

<style scoped>
#main {
  min-height: 84vh;
} 
</style>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import 'https://arpen-header-footer-prod.codebit.biz/header-footer.css';
#appContainer {
  overflow: v-bind(bodyOverflow);
  height: 100vh;
}
#content {
  background-color: white;
  @include media-breakpoint-up(sm) {
    margin-left: 75px;
  }
}
</style>